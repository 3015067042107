@import "./mixin.scss";
@import "./var.scss";

.form_contactos {
	box-shadow: 0px 0px 2px 2px $gris-claro;
	padding: 2vw;
	border-radius: 20px;
}

.labels,
.inputs,
.select {
	width: 100%;
	text-transform: uppercase;
	font-size: 0.8vw;
	display: inline-block;
	vertical-align: top;

}

.inputnone {
	text-transform: none !important;
}

.labels {
	margin-top: 0;
}

.labels_check {
	font-size: 1vw;
	vertical-align: top;
	display: inline-block;
}

.inputs {
	border: 0;
	padding: 0;

	&::placeholder {
		font-weight: bold;
	}
}

.select {
	border: 0;
	display: inline-block;
}

.formgrupos {
	width: 100%;
	position: relative;
	display: inline-block;
	border: 1px solid $gris;
	padding: 0.5vw 1.5vw;
	//line-height: 100%;
	-webkit-border-radius: 10px;
	-moz-border-radius: 10px;
	border-radius: 10px;

	span {
		display: block;
		color: tomato;
		font-size: x-small;
	}
}

@media (min-width: 768px) and (max-width: 1200px) and (orientation: portrait) {

	.labels,
	.inputs,
	.select {
		font-size: 1.1rem;
	}

	.body_form {
		flex-direction: column;
	}
}

@media (min-width: 768px) and (max-width: 1200px) and (orientation: landscape) {

	.labels,
	.inputs,
	.select {
		font-size: 1.2rem;
	}
}

// tablet MD
@media (min-width: 577px) and (max-width: 767px) and (orientation: landscape) {

	.labels,
	.inputs,
	.select {
		font-size: 1.2rem;
	}

	.body_form {
		flex-direction: column;
	}

	.labels,
	.inputs,
	.select {
		font-size: 0.8rem;
	}
}

@media (min-width: 577px) and (max-width: 767px) and (orientation: portrait) {

	.labels,
	.inputs,
	.select {
		font-size: 1.2rem;
	}

	.body_form {
		flex-direction: column;
	}

	.labels,
	.inputs,
	.select {
		font-size: 0.8rem;
	}
}

@media (max-width: 576px) {
	.body_form {
		flex-direction: column;
	}

	.form_contactos {
		padding: 2rem;
	}

	.labels,
	.inputs,
	.select {
		font-size: 0.8rem;
	}

	.labels_check {
		font-size: 0.8rem;
	}

	.labels,
	.inputs,
	.select {
		font-size: 0.8rem;
	}
}