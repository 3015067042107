@import "./var.scss";
@import "./mixin.scss";
.gridy {
	display: grid;
	gap: 1rem;
	grid-auto-rows: 22rem;
	grid-template-columns: repeat(auto-fill, minmax(min(100%, 20vw), 1fr));
}

.featured {
	grid-column: span 2;
}

.cardContainer {
	display: grid;
	grid-template-columns: repeat(
		auto-fill,
		minmax(20vw, 1fr)
	); /* Mínimo 20vw, máximo 1fr */
	grid-gap: 1vw; /* Espaciado entre las tarjetas */
}

.card {
	-webkit-border-radius: 10px;
	-moz-border-radius: 10px;
	border-radius: 10px;
	position: relative;
	&:hover {
		//filter: blur(1px);
		cursor: pointer;
		.centerAbsolute {
			display: block;
			cursor: pointer;
		}
	}
	&:active,
	&:focus {
		background-color: rgb(244 121 33 / 90%);
		filter: blur(0);
	}
}

.franjaShadown {
	position: absolute;
	bottom: 0;
	left: 0;
	width: 100%;
	background-color: rgba(0, 0, 0, 0.4);
	padding: 10px;
	box-sizing: border-box;
	-webkit-border-bottom-right-radius: 10px;
	-webkit-border-bottom-left-radius: 10px;
	-moz-border-radius-bottomright: 10px;
	-moz-border-radius-bottomleft: 10px;
	border-bottom-right-radius: 10px;
	border-bottom-left-radius: 10px;
}

.btnProject {
	font-size: 1.2vw;
	padding: 0.5vw 3vw;
	font-weight: 500;
	-webkit-border-radius: 10px;
	-moz-border-radius: 10px;
	border-radius: 10px;
	text-decoration: none;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
	display: inline-block;
	white-space: nowrap;
	@include toAnim(0.3s);
	border: 0;
	position: relative;
	background-color: $naranja;
	color: white;
	filter: blur(0);
	&:active,
	&:focus,
	&:hover,
	&:visited {
		background-color: rgb(244 121 33 / 90%) !important;
		color: white;
		border-color: $naranja !important;
		box-shadow: oulet;
		-webkit-box-shadow: oulet;
		-moz-box-shadow: oulet;
		filter: blur(0);
	}
}

.btnCity {
	font-size: 0.8vw;
	padding: 0.5vw 1.5vw;
	font-weight: 400;
	border-radius: 4px;
	text-decoration: none;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
	display: inline-block;
	white-space: nowrap;
	transition: all 0.3s ease-in-out;
	border: 0;
}

.centerAbsolute {
	display: none;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	filter: blur(0);
	&:active,
	&:focus,
	&:hover {
		filter: blur(0);
		display: block;
		cursor: pointer;
	}
}

.btnClose {
	border: 1px solid rgb(87 88 90 / 13%);
	color: $naranja;
}

//desktop
@media (min-width: 1200px) and (max-width: 1920px) {
	.contMediun {
		max-width: 92%;
		margin: auto;
	}
}

//big desktop
@media (min-width: 1920px) {
}

//desktop
@media (min-width: 1200px) {
}

//only tablet portrait
@media only screen and (min-width: 768px) and (max-width: 1200px) and (orientation: portrait) {
	.cardContainer {
		grid-template-columns: repeat(2, 1fr);
		grid-gap: 2.5rem;
		padding: 0 3rem;
	}

	.btnCity {
		font-size: 0.8rem;
		padding: 0.5rem 1.5rem;
	}

	.btnProject {
		font-size: 1.2rem;
		padding: 0.5rem 1rem;
		&:active {
			background-color: rgb(244 121 33 / 90%);
		}
	}
}

//only tablet landscape
@media only screen and (min-width: 768px) and (max-width: 1200px) and (orientation: landscape) {
	.cardContainer {
		grid-template-columns: repeat(4, 1fr);
		grid-gap: 1.5rem;
		padding: 0 2rem;
	}
}

// tablet MD
@media (min-width: 577px) and (max-width: 767px) and (orientation: landscape) {
	.cardContainer {
		grid-template-columns: repeat(2, 1fr);
		grid-gap: 0.5rem;
		padding: 0 1rem;
	}

	.btnCity {
		font-size: 0.8rem;
		padding: 0.5rem 1.5rem;
	}

	.btnProject {
		font-size: 1.2rem;
		padding: 0.5rem 1rem;
		&:active {
			background-color: rgb(244 121 33 / 90%);
		}
	}
}

@media (min-width: 577px) and (max-width: 767px) and (orientation: portrait) {
	.cardContainer {
		grid-template-columns: repeat(2, 1fr);
		grid-gap: 0.5rem;
		padding: 0 1rem;
	}

	.btnCity {
		font-size: 0.8rem;
		padding: 0.5rem 1.5rem;
	}

	.btnProject {
		font-size: 1.2rem;
		padding: 0.5rem 1rem;
		&:active {
			background-color: rgb(244 121 33 / 90%);
		}
	}
}

//only phone
@media (max-width: 576px) {
	.cardContainer {
		grid-template-columns: repeat(1, 1fr);
		grid-gap: 0.5rem;
		padding: 0 1rem;
	}

	.btnCity {
		font-size: 0.8rem;
		padding: 0.5rem 1.5rem;
		filter: blur(0);
	}

	.btnProject {
		font-size: 1.2rem;
		padding: 0.5rem 1rem;
		&:active,
		&:focus {
			background-color: rgb(244 121 33 / 90%);
			filter: blur(0);
		}
	}

	.centerAbsolute {
		display: block;
		filter: blur(0);
	}
}
