@use "mixin.scss";
@use "var.scss";

.h1 {
	/* font-size: 40px; */
	font-size: 2vw;
}

.h1_hidden {
	font-size: 1px;
	color: #f1f1f1
}

.h2 {
	/* font-size: 32px; */
	font-size: 1.667vw;
}

.h3 {
	/* font-size: 24px; */
	font-size: 1.25vw;
}

.p {
	/* font-size: 16px; */
	font-size: 0.8vw;

	small {
		/* font-size: 14px; */
		font-size: 0.7vw;
	}
}

.pSmall {
	.p {
		/* font-size: 14px; */
		font-size: 0.7vw;
	}
}

.text_speciales {
	font-size: 1.1vw;
}

.textBanner {
	section {
		h3 {
			@extend .p;
		}
	}

	h3 {
		@extend .p;
	}
}

.contentAdmin {
	h1,
	h4 {
		@extend .h1;
	}

	h2,
	h5 {
		@extend .h2;
		font-weight: bold;
		margin-bottom: 2rem;
	}

	h3,
	h6 {
		@extend .h3;
	}

	p,
	li {
		@extend .p;
	}

	// normalmente no se deben traer todos estos estilos en el modulo de texto,
	//solo deberian ser font-size en este modulo, pero por el proyecto al tener que cargar listas desde el admin fue necesario
	ul {
		list-style: none;
		padding: 0;

		li {
			margin-bottom: 1.5rem;

			&::before {
				content: "";
				display: inline-block;
				width: 1.375rem;
				height: 1.375rem;
				vertical-align: middle;
				border: 2px solid #ebac5b;
				margin-right: 0.5rem;
				border-radius: 50%;
				-webkit-border-radius: 50%;
				-moz-border-radius: 50%;
				-ms-border-radius: 50%;
				-o-border-radius: 50%;
			}
		}
	}
}

.contentLegal {
	h1,
	h4 {
		@extend .h1;
	}

	h2,
	h5 {
		@extend .h2;
		font-weight: bold;
		margin-bottom: 2rem;
	}

	h3,
	h6 {
		@extend .h3;
	}

	p,
	li {
		@extend .p;
	}

	p,
	span {
		display: inline-block;
	}

	ul {
		list-style: disc;
		//padding: 0;
		margin: 0;
	}

	ol {
		list-style: none;
		counter-reset: li;

		& > li:before {
			content: counter(li) ".";
			color: #ebac5b;
			display: inline-block;
			width: 1em;
			margin-left: -1em;
			font-size: 1.2vw;
		}

		& > li {
			counter-increment: li;
			@extend .p;
		}
	}
}

.paragraph {
	font-size: 0.8vw;

	p {
		font-size: 0.8vw;
	}
}

.specialText {
	p {
		font-size: 0.8vw;
	}
}

.textosApis {
	p {
		margin-bottom: 0;
	}
}

.text3lineas,
.text4lineas {
	text-overflow: ellipsis;
	overflow: hidden;
	display: -webkit-box;
	-webkit-box-orient: vertical;
}

.text3lineas {
	-webkit-line-clamp: 3;
}

.text4lineas {
	-webkit-line-clamp: 4;
}

.frase {
	font-size: 1.5vw;
}

.textH2_especiales {
	/* font-size: 32px; */
	font-size: 1.667vw !important;
}

// big desktop
@media only screen and (min-width: 1921px) {
	.frase {
		font-size: 1.5rem;
	}

	.h1 {
		/* font-size: 40px; */
		font-size: 2.5rem;
	}

	.h2 {
		/* font-size: 30px; */
		font-size: 1.8rem;
	}

	.h3 {
		/* font-size: 22px; */
		font-size: 1.3rem;
	}

	.h4 {
		font-size: 1.1rem;
	}

	.h4Special {
		/* font-size: 80px;
        font-size: 4.6vw; */
		font-size: 4.7rem;
	}

	.p {
		font-size: 1.1rem;

		small {
			font-size: 1rem;
		}
	}

	.pSmall {
		.p {
			font-size: 1rem;
		}
	}

	.input {
		font-size: 1.1rem;
	}

	.paragraph {
		font-size: 1.1rem;

		p {
			font-size: 1.1rem;
		}
	}

	.textH2_especiales {
		font-size: 1.8rem !important;
	}
}

// only desktop XL
@media only screen and (min-width: 1200px) and (max-width: 1920px) {
	/* .tituloBannerinvertir {
		width: 30%;
	} */

	.textBannerinvertir {
		width: 70%;
	}
}

// big tablet LG
@media (max-width: 1200px) {
	.h1 {
		/* font-size: 40px; */
		/* font-size: 5rem; */
		font-size: 4.2rem;
	}

	.h2 {
		/* font-size: 30px; */
		font-size: 1.6rem;
	}

	.h3 {
		/* font-size: 22px; */
		font-size: 1.2rem;
	}

	.p {
		/* font-size: 18px; */
		font-size: 1rem;

		small {
			/* font-size: 14px; */
			font-size: 0.8rem;
		}
	}

	.pSmall {
		.p {
			font-size: 0.8rem;
		}
	}

	.special-text {
		font-size: 12px;
	}

	.contentLegal {
		ol {
			& > li:before {
				font-size: 1.5rem;
			}

			& > li {
				font-size: 1;
			}
		}
	}

	.textH2_especiales {
		font-size: 1.6rem !important;
	}
}

//only tablet portrait
@media only screen and (min-width: 768px) and (max-width: 1200px) and (orientation: portrait) {
	.frase {
		font-size: 1.5rem;
	}
}

//only tablet
@media only screen and (min-width: 768px) and (max-width: 1200px) and (orientation: landscape) {
	.h1 {
		font-size: 3.8rem;
	}

	.h2 {
		font-size: 1.3rem;
	}

	.h3 {
		font-size: 1.1rem;
	}

	.p {
		font-size: 0.8rem;
	}

	.special-text {
		font-size: 10px;
	}

	.paragraph {
		font-size: 0.8rem;

		p {
			font-size: 0.8rem;
		}
	}

	.frase {
		font-size: 1.5rem;
	}
	.textH2_especiales {
		font-size: 1.3rem !important;
	}
}

// tablet MD
@media (max-width: 768px) {
	.frase {
		font-size: 1.5rem;
	}

	.h1 {
		font-size: 1.5rem;
	}

	.h2 {
		font-size: 1.2rem;
	}

	.h3 {
		font-size: 1rem;
	}

	.p {
		font-size: 0.8rem;

		small {
			font-size: 0.7rem;
		}
	}

	.pSmall {
		.p {
			font-size: 0.7rem;
		}
	}

	.input {
		font-size: 0.8rem;
	}

	.paragraph {
		font-size: 0.8rem;

		p {
			font-size: 0.8rem;
		}
	}

	.special-text {
		font-size: 12px;
	}
	.textH2_especiales {
		font-size: 1.2rem !important;
	}
}

// only mobile

@media (max-width: 576px) {
	.frase {
		font-size: 1.5rem;
	}

	.h1 {
		font-size: 2.5rem;
	}

	.h2 {
		font-size: 1.4rem;
	}

	.h3 {
		font-size: 1.2rem;
	}

	.p {
		font-size: 0.8rem;

		small {
			font-size: 0.7rem;
		}
	}

	.pSmall {
		.p {
			font-size: 1rem;
		}
	}

	.input {
		font-size: 0.8rem;
	}

	.paragraph {
		font-size: 0.8rem;

		p {
			font-size: 0.8rem;
		}
	}

	.special-text {
		font-size: 12px;
	}
	.textH2_especiales {
		font-size: 1.4rem !important;
	}
}
