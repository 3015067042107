@import './var.scss';
@import './mixin.scss';

.botnScroll {
    display: flex;
    align-items: center;
    justify-content: center;
}

.scroll_to_top_button {
    position: fixed;
    bottom: 2vw;
    //right: 1.042vw;
    background-color: $naranja;
    color: #fff;
    border: none;
    border-radius: 50%;
    //width: 2.083vw;
    //height: 2.083vw;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.7vw;
    cursor: pointer;
    opacity: 0;
    transition: opacity 0.3s;
    transform: rotate(270deg);
}

.scroll_to_top_button.visible {
    opacity: 1;
    z-index: 1045;
}

// big desktop
@media only screen and (min-width: 1920px) {}

// only desktop XL
@media only screen and (min-width: 1200px) and (max-width: 1920px) {}

//Laptop
@media only screen and (min-width: 1200px) and (max-width: 1600px) {}

//only tablet
@media only screen and (min-width: 768px) and (max-width: 1200px) {
    .scroll_to_top_button {
        font-size: 1.7rem;
    }
}

// big tablet LG
@media (max-width: 1200px) {}

// tablet MD
@media (max-width: 767px) {
    // .scroll_to_top_button {
    //     font-size: 1.7rem;
    //     // bottom: 5rem;
    // }
}

// only mobile

@media (max-width: 576px) {

    .botnScroll{
        justify-content: center;
        padding: 0 2.5%;
    }

    // .scroll_to_top_button {
    //     font-size: 1.7rem;
    //     // bottom: 1.5rem;
    // }

}

@media (max-width: 992px) {
    .scroll_to_top_button {
        font-size: 1.7rem;
        bottom: 5rem;
    }
}