@import './var.scss';
@import './mixin.scss';

.btn {
    border-bottom: 1px solid $gris;
    font-size: 0.85vw;
}

//big desktop
@media (min-width: 1920px) {}

@media (max-width: 1200px) {

}
//desktop
@media (min-width: 1200px) and (max-width: 1920px) {}

//only tablet portrait
@media only screen and (min-width: 768px) and (max-width: 1200px) and (orientation: portrait) {
    .btn {
        border-bottom: 1px solid $gris;
        font-size: 0.9rem;
    }
}

//only tablet landscape
@media only screen and (min-width: 768px) and (max-width: 1200px) and (orientation: landscape) {
    .btn {
        font-size: 0.9rem;
    }
}

//only phone
@media (max-width: 767px) {
    .btn {
        font-size: 0.9rem;
    }

}

//only phone
@media (max-width: 576px) {

}