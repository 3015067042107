@import "./mixin.scss";
@import "./var.scss";

.parent {
	display: flex;
	justify-content: center;
	align-items: center;


	div {
		position: relative;
		padding-bottom: 0vw;
	}

	input[type="radio"] {
		display: none;
	}
}



.son {
	position: relative;
	display: inline-block;
	border: 2px solid $naranja;
	border-radius: 0.5vw;
	cursor: pointer;
	font-size: 1vw;
	padding: 0.8vw 8vw;
	color: #57585a;
	text-align: center;
	color: $naranja;
	font-weight: 700;
	width: 32vw;
}

.icon {
	width: 2.3vw;
	height: 2.2vw;
	margin: 0.3vw auto;
}

/* Estilo para cuando el botón está seleccionado */
input:checked+.son {
	background-color: $naranja;
	color: white;
	border: 1px solid $naranja;

	.icon {
		filter: brightness(800%);
	}
}

.cardpagos {
	width: 90%;
}

//big desktop
@media (min-width: 1920px) {
	.son {
		font-size: 1.5rem;
	}

	.parent {
		display: flex;
		flex-wrap: nowrap;
	}
}

@media (max-width: 767px) {
	.son {
		padding: 0.8rem 0.8rem;
		border-radius: 0.5rem;
		position: relative;
		// margin-right: 0.6rem;
		font-size: 1.2rem;
	}

	.parent {
		div {
			position: relative;
			padding-bottom: 1rem;
		}
	}

	.icon {
		width: 2.3rem;
		height: 2.2rem;
		margin: 0.3rem auto;
	}

	.son {
		padding: 0.8rem 1.3rem;
		font-size: 1.2rem;
	}
}

//desktop
@media (min-width: 1200px) and (max-width: 1920px) {
	.contMediun {
		max-width: 92%;
		margin: auto;
	}
}

//desktop
@media (min-width: 1200px) {}

//only tablet portrait
@media only screen and (min-width: 768px) and (max-width: 1200px) and (orientation: portrait) {
	.icon {
		width: 2rem;
		height: 2rem;
		margin: 0.3rem auto;
	}

	.son {
		padding: 0.8rem 1.3rem;
		font-size: 1.2rem;
		width: 22rem;
	}
}

//only tablet landscape
@media only screen and (min-width: 768px) and (max-width: 1200px) and (orientation: landscape) {
	.icon {
		width: 2.3rem;
		height: 2.2rem;
		margin: 0.3rem auto;
	}
}

@media only screen and (min-width: 577px) and (max-width: 767px) {
	.son {
		width: 100%;
	}

	.parent {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

	}
}

//only phone
@media (max-width: 576px) {
	.icon {
		width: 2.3rem;
		height: 2.2rem;
		margin: 0.3rem auto;
	}

	.son {
		padding: 0.8rem 1.3rem;
		font-size: 1.2rem;
		width: 100%;
	}

	.parent {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;

	}
}