@import "./var.scss";
@import "./fonts.scss";
@import "./mixin.scss";

.barralateral {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    box-shadow: 1px 1px 10px 0px #F4792140;
    height: 100%;
}

.navbarra {
    list-style: none;
}

.subNavItem {
    font-size: 1.250vw;
    text-decoration: none;
    color: $gris;
    padding: 0.6vw 1.3vw;
}

.active {
    box-shadow: 0px 0px 10px 0px #00000040;
    color: $naranja;
    padding: 0.5vw 1.2vw;
    border-radius: 0.521vw;
    cursor: pointer;
    margin-right: 1.9vw;

    .subNavItem {
        color: $naranja;
        padding: 0vw;
        font-weight: 700;
    }
}

.itenend {
    position: relative;
    padding-bottom: 10vw;
}

.headermenu {
    padding: 2.5vw 3.5vw;
}


//only tablet portrait
@media only screen and (min-width: 768px) and (max-width: 1200px) and (orientation: portrait) {
    .headermenu {
        padding: 2.2rem 2.5rem;
    }

    .active {

        padding: 0.5rem 0.8rem;
        border-radius: 0.521rem;
    }

    .subNavItem {
        padding: 0.5rem 0.8rem; 
        font-size: 0.9rem;
    }

}

//only tablet landscape
@media only screen and (min-width: 768px) and (max-width: 1200px) and (orientation: landscape) {
    .headermenu {
        padding: 2.2rem 2.8rem;
    }
    .subNavItem {
        padding: 0.5rem 0.8rem; 
        font-size: 1rem;
    }

}


@media (max-width: 767px) {
    .headermenu {
        padding: 1rem 2.8rem;
    }

    .active {

        padding: 0.5rem 1.2rem;
        border-radius: 0.521rem;
    }

    .subNavItem {
        padding: 0.5rem 1.2rem;
    }

    .barralateral {
        //width: 70%;
        max-width: 90%;
        position: fixed;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 9999;
        @include toAnim(0.3s);
        background-color: white;
        opacity: 0;
        visibility: hidden;
        padding: 1.5rem;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;
        border-radius: 10px;
        box-shadow: 0 1px 5px rgb(0, 0, 0, 0.25);
    }

    .showbarralateral {
        opacity: 1;
        visibility: visible;
    }

    .subNavItem {
        font-size: 0.8rem;

    }

    @media(max-height:380px) {
        .listamenu {
            max-height: 10rem;
            overflow: auto;
        }

    }

    @media (min-height:380px) and (max-height:669px) {
        .listamenu {
            max-height: 20rem;
            overflow: auto;
        }


    }

}

// all tablet 
@media (min-width: 768px) and (max-width: 1200px) {
    
}


// only mobile

@media (max-width: 576px) {
    .subNavItem {
        font-size: 1.25rem;
    }

    .barralateral {
        width: 100%;
    }

    .headermenu {
        padding: 2.2rem 2.8rem;
    }

    .active {

        padding: 0.5rem 1.2rem;
        border-radius: 0.521rem;
    }
}

@media(max-width: 376) {
    .headermenu {
        padding-top: 3rem;
    }
}